@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Karla:ital,wght@0,200..800;1,200..800&family=Press+Start+2P&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Give+You+Glory&family=Libre+Barcode+128+Text&family=Redacted+Script:wght@300;400;700&display=swap");

body {
  background-image: url("../public/assets/star_bg_outline.png");
  background-repeat: no-repeat;
  background-size: auto 100vh;
  background-color: #fdfdf4;
  color: black;
  /* background-attachment: fixed; Fixes the background image */
  margin: 0px;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1800px) {
  body {
    background-size: contain;
  }
}

/* -------------- text -------------- */
* {
  font-family: "Karla";
  font-size: 17px;
}

h1 {
  font-family: "Press Start 2P";
  font-size: 50px;
}

h2 {
  font-family: "Press Start 2P";
  font-size: 36px;
  margin-bottom: 40px;
}

h3 {
  font-family: "Press Start 2P";
  font-size: 18px;
}

p {
  font-family: "Karla";
  font-size: 17px;
}

@media screen and (max-width: 768px) {
  * {
    font-size: 2.34vw;
  }

  h1 {
    font-size: 6.5vw;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 2.35vw;
  }

  p {
    font-size: 17px;
  }
}

@media screen and (max-width: 560px) {
  /*min font size is 12 if smaller than 595*/
  p {
    font-size: 16px;
  }
}

@media screen and (max-width: 430px) {
  p {
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
  }
}

button {
  border: none;
  border-radius: 10px;
}

/* -------------- section css -------------- */
section {
  /* display: grid; */
  display: flex;
  place-items: center;
  /* min-height: 100vh; */
  box-sizing: border-box;
  width: 100%;
}

.section-container {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 105px;
  margin-bottom: 50px;
  /* max-width: 900px; */
  width: 100%;
  min-height: 100vh;
  /* min-width: 300px; */
  transition: margin 0.5s;
  /* align-items: center; */
}

@media screen and (max-width: 1024px) {
  .section-container {
    margin-left: 150px;
    margin-right: 150px;
  }
}

@media screen and (max-width: 976px) {
  .section-container {
    /* max-width: 75rem;
    box-sizing: border-box;
    padding: 10px; */
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media screen and (max-width: 595px) {
  .section-container {
    /* max-width: 75rem;
  box-sizing: border-box;
  padding: 10px; */
    margin-left: 20px;
    margin-right: 20px;
  }
  .section-container > h2,
  .section-container > .projects-section > h3 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 430px) {
  .section-container {
    margin-left: 3px;
    margin-right: 3px;
  }

  .section-container > h2,
  .section-container > .projects-section > h3 {
    margin-left: 30px;
  }
}

.shadow {
  box-shadow: inset -3px -3px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #000000;
}

.box-shadow {
  /* box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2); */
  border: 1px solid #000000;
}
