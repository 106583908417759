.outer-about-box {
  display: inline-flex;
  align-items: end;
}

.about-box {
  display: flex;
  background-color: #fffacb;
  /* background-color: #6981d5; */
  border-radius: 10px;
  justify-content: space-between;
  padding: 40px;
  width: 70vw;
  /* color: white; */
}

/* .about-box-inner {
  display: flex;
  width: 65vw;
} */

.about-blurb {
  /* background-color: white; */
  border-radius: 10px;
  padding: 40px;
  width: 30vw;
  margin-right: 50px;
  align-content: center;
}

.about-blurb h3 {
  margin-bottom: 20px;
}

/* .about-blurb p {
  font-size: 16px;
} */

.about-images-container {
  display: flex;
  flex-direction: column;
  width: 25vw;
  margin-top: 40px;
  margin-right: 40px;
  /* align-items: center; */
  /* justify-content: space-between; */
  height: 100%;
}

.top-image-container {
  display: inline-flex;
  justify-content: space-between;
  width: 25vw;
  margin-bottom: 20px;
}

.top-image {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 12vw;
  height: 12vw;
}

.spotify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spotify-container h3 {
  font-size: 10px;
}

.spotify {
  width: 25vw;
  height: 200px;
}

.about-star {
  margin-left: -50px;
  width: 130px;
  height: 130px;
  transform: rotate(38deg);
  margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  .about-box {
    flex-direction: column;
  }

  .about-blurb {
    width: 60vw;
    margin-right: 0px;
  }

  .about-images-container {
    width: 60vw;
    margin-top: 0px;
    margin-right: 0px;
    padding: 40px;
    align-items: center;
  }

  .top-image-container,
  .spotify {
    width: 50vw;
  }

  .top-image {
    width: 23vw;
    height: 23vw;
  }
}

@media screen and (max-width: 720px) {
  .about-star {
    width: 110px;
    height: 110px;
    margin-left: -60px;
  }
}

@media screen and (max-width: 560px) {
  .about-box {
    padding: 30px;
    margin-left: 40px;
    width: 80vw;
  }

  .about-blurb {
    padding: 20px;
    width: 70vw;
  }

  .about-images-container {
    width: 70vw;
  }

  .top-image-container,
  .spotify {
    width: 60vw;
  }

  .top-image {
    width: 27vw;
    height: 27vw;
  }

  .about-star {
    display: none;
    /* margin-left: -50px;
    width: 100px;
    height: 100px; */
  }
}

@media screen and (max-width: 430px) {
  .about-box {
    padding: 20px;
    margin-left: 20px;
    width: 90vw;
  }

  .about-blurb {
    width: 80vw;
  }

  .about-images-container {
    width: 80vw;
  }

  .top-image-container {
    width: 65vw;
  }

  .top-image {
    width: 30vw;
    height: 30vw;
  }

  .spotify {
    width: 65vw;
    height: 100px;
  }

  .about-star {
    display: none;
    /* margin-left: -40px;
    width: 50px;
    height: 50px; */
  }
}
