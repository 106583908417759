.projects-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: black; */
}

.projects-container {
  padding-top: 80px;
  background-color: #262626;
}

.projects-container h2 {
  color: white;
}

@media screen and (max-width: 560px) {
  .projects-container {
    padding-top: 40px;
  }
}
