.contact-container {
  padding-top: 80px;
}

.contact-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.paperclip-container {
  width: 40vw;
  min-width: 500px;
  display: flex;
  justify-content: right;
  z-index: 20;
}

.contact-box {
  display: flex;
  flex-direction: column;
  background-color: #fffacb;
  padding: 30px;
  border-radius: 10px;
  min-width: 500px;
  width: 40vw;
  transition: transform 0.3s ease;
}
/* 
.contact-box:hover,
.paperclip-container:hover {
  transform: translateY(-5px);
} */

.contact-header {
  /* grid-column-start: 1;
  grid-column-end: 3; */
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.contact-header h3 {
  font-size: 10px;
}

.contact-main-content {
  display: flex;
  margin-right: 10px;
}

.pic-container {
  display: flex;
  min-width: 200px;
  min-height: 200px;
  width: 16vw;
  height: 16vw;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 10px;
  margin-right: 30px;
}

.contact-text {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.contact-row {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 40px;
  /* padding: 5px; */
}

.contact-row .social-link {
  background-color: black;
  color: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  min-width: 100px;
  text-align: center;
  width: 10vw;
}

.contact-row .social-link:hover {
  background-color: #6981d5;
  color: black;
  border: 1px solid black;
}

.social-link h3 {
  font-size: 10px;
  /* font-family: "Karla"; */
}

.scribble {
  font-family: "Redacted Script";
  font-size: 20px;
  margin-right: 10px;
  /* min-width: 80px; */
}

.barcode {
  font-family: "Libre Barcode 128 Text";
  font-size: 25px;
}

.signature {
  font-family: "Give You Glory";
  /* text-align: right; */
  margin-top: 30px;
  /* margin-right: 40px; */
}

.paperclip {
  width: 35px;
  transform: rotate(20deg);
  margin-bottom: -22px;
  margin-right: 50px;
  /* height: auto; */
}

.contact-star1 {
  position: relative;
  width: 90px;
  height: 90px;
  transform: rotate(38deg);
  margin-top: -100px;
  left: -21vw;

  /* margin-right: -200px; */
}

.contact-star2 {
  width: 90px;
  height: 90px;
  transform: rotate(10deg);
  margin-top: 50px;
  margin-left: -280px;
}

@media screen and (max-width: 1200px) {
  .contact-star1 {
    left: -300px;
  }
}

@media screen and (max-width: 560px) {
  .paperclip-container {
    width: 60vw;
    min-width: 300px;
  }

  .contact-box {
    width: 60vw;
    min-width: 300px;
  }
  .contact-main-content {
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
  }

  .pic-container {
    margin-right: 0px;
  }

  .contact-row {
    margin-right: 0px;
  }

  .contact-text {
    margin-top: 10px;
  }

  .contact-container {
    padding-top: 40px;
  }
}
