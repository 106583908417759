.project-container {
  display: inline-flex;
  margin-bottom: 50px;
  align-items: center;
}

.project-description {
  margin-top: 30px;
  margin-left: 30px;
  /* margin-right: 30px; */
  padding: 30px;
  /* padding-right: 5vw; */
  z-index: 5;
  max-width: 600px;
  max-height: 600px;
}

.project-description .title {
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
  max-width: 30vw;
}

.project-description .subtitle {
  font-weight: bold;
  margin-bottom: 30px;
}

.project-description-container {
  /* background-color: #6981d5; */
  background-color: #6981d5;
  color: white;
  border-radius: 10px;
  width: 40vw;
  max-width: 600px;
  min-height: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 8vw;
}

.project-description h3 {
  font-size: 18px;
}

/* .project-description p {
  font-size: 16px;
} */

.project-image-container {
  background-color: #fffacb;
  border-radius: 10px;
  margin-left: -50px;
  padding: 25px;
  max-width: 600px;
  width: 35vw;
  transition: transform 0.3s ease;
}

.project-image-container:hover {
  transform: translateY(-5px);
}
.project-image-container .image {
  border-radius: 10px;
  margin-top: 15px;
  /* min-height: 200px; */
}

.project-image-container h3 {
  text-align: right;
  color: black;
}

.project-description-container button {
  background-color: none;
  padding: 0px;
}

.github {
  width: 32px;
}

.github:hover {
  /* fill: #a6b6f0; */
  transform: scale(1.05);
}

.badges {
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 30px;
}

.badge {
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  min-width: 50px;
  background-color: rgb(62, 86, 223);
  color: white;
  text-align: center;
  white-space: nowrap;
  /* border: 1px solid #000000; */
  /* max-height: 40px; */
}

.badge p {
  font-size: 12px;
}

@media screen and (min-width: 1200px) {
  .project-description .subtitle {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 976px) {
  .project-container {
    display: flex;
    flex-direction: column;
  }
  .project-description-container {
    width: 70vw;
    min-height: 35vw;
    display: flex;
    flex-direction: column;
    padding-right: 0px;
  }

  .project-description {
    margin-top: 30px;
    margin-left: 30px;
    padding: 30px;
    max-height: 400px;
    max-width: 60vw;
  }

  .project-image-container {
    margin-left: 0px;
    margin-top: -30px;
    max-width: 65vw;
    padding: 30px;
    width: 65vw;
  }
}

@media screen and (max-width: 560px) {
  .project-description-container {
    max-width: 80vw;
  }

  .project-image-container {
    margin-left: 0px;
    margin-top: -30px;
    max-width: 65vw;
    padding: 17px;
    width: 65vw;
  }

  .project-description {
    margin-top: 23px;
    margin-left: 23px;
    padding: 20px;
    max-width: 60vw;
  }

  .project-description h3 {
    font-size: 16px;
  }

  /* .project-description p {
    font-size: 14px;
  } */

  .badges {
    display: inline-flex;
    flex-wrap: wrap;
    margin-left: 23px;
    margin-bottom: 23px;
    padding: 20px;
  }

  .badge {
    padding: 10px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    min-width: 40px;
    /* max-height: 40px; */
  }

  .badge p {
    font-size: 11px;
  }

  .github {
    width: 24px;
  }
}

@media screen and (max-width: 430px) {
  .project-image-container {
    margin-left: 0px;
    margin-top: -20px;
    max-width: 65vw;
    padding: 15px;
    width: 65vw;
  }

  .project-description {
    margin-top: 20px;
    margin-left: 20px;
    padding: 15px;
    max-width: 60vw;
  }

  .project-description h3 {
    font-size: 12px;
  }

  /* .project-description p {
    font-size: 10px;
  } */

  .badges {
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 15px;
  }

  .badge {
    padding: 6px;
    min-width: 30px;
  }

  .badge p {
    font-size: 10px;
  }

  .github {
    width: 18px;
  }
}

@media screen and (max-width: 350px) {
  .project-description h3 {
    font-size: 10px;
  }
}
