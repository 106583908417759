/* -------------- home -------------- */
.home-section {
  justify-content: center;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 213px;
  margin-right: 5vh;
  /* margin-top: 150px; */
  max-width: 600px;
  min-height: 100vh;
  min-width: 300px;
  transition: margin 0.5s;
  color: black;
}
.home-section p {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

@media screen and (min-width: 1500px) {
  .home-section {
    margin-right: 10vh;
    margin-left: 20vw;
  }
}

@media screen and (min-width: 1200px) {
  .home-section {
    margin-right: 10vh;
  }
}
@media screen and (max-width: 976px) {
  .home-section {
    max-width: 75rem;
    box-sizing: border-box;
    padding: 10px;
    margin-left: 100px;
    margin-right: 50px;
    min-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .home-section {
    max-width: 75rem;
    box-sizing: border-box;
    padding: 10px;
    margin-left: 55px;
    margin-right: 20px;
    min-width: 200px;
  }

  .home-section p {
    font-size: 17px;
  }
}

@media screen and (max-width: 430px) {
  .home-section {
    max-width: 75rem;
    box-sizing: border-box;
    padding: 10px;
    /* margin-top: 75px; */
    margin-left: 40px;
    margin-right: 0px;
    min-width: 200px;
  }

  .home-section p {
    font-size: 16px;
  }
}

.cv-button {
  border-radius: 10px;
  width: 20%;

  min-width: 150px;
  padding: 18px 10px;
  background-color: black;
  color: white;
  border: 1px solid #000000;
  text-align: center;
}

.cv-button:hover {
  background-color: rgb(93, 121, 219);
  color: black;
}

.home-img-container {
  display: flex;
  margin-right: 80px;
}

.character {
  width: 40.57vw;
  max-width: 400px;
  /* display: none; */
}

/* .character:hover {
  transform: scale(1.02);
} */

@media screen and (max-width: 768px) {
  .character {
    width: 40.57vw;
  }
}

@media screen and (max-width: 425px) {
  .character {
    width: 30vw;
  }
}

.h2 {
  margin-bottom: 7px;
  font-family: karla;
}
